body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
  /* background-color: #FAFAFA; */
  background-color: #FFFFFF;
}

/* .App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
} */

.App-header {
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

/*=========================*/

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  -webkit-transition: opacity 250ms ease-in;
  transition: opacity 250ms ease-in;
}
.tone-player-grid {
  position: relative;
}
/* https://github.com/lillydinhle/react-piano-component/blob/master/src/demo/components/InteractivePiano.css */
.the-piano__piano-container {
  display: inline-flex;
  box-sizing: border-box;
  border-top: 10px solid #ed5276;
  position: relative;
  margin: auto;
}

.the-piano__piano-container::after {
  content: '';
  width: 100%;
  height: 5px;
  background-color: rgba(68, 68, 68, 0.1);
  position: absolute;
  top: 0;
}

.the-piano__accidental-key__wrapper {
  position: relative;
  width: 0;
}

.the-piano__accidental-key {
  position: absolute;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  cursor: pointer;
  background: #444;
  width: 36px;
  height: 120px;
  border-radius: 0px 0px 3px 3px;
  border: 3px solid #444;
  border-top: none;
  box-sizing: border-box;
  padding: 5px;
  outline: none;
  color: #dbdbdb;
}

.the-piano__accidental-key--playing {
  background: #ed5276;
  color: #fff;
}

.the-piano__natural-key {
  cursor: pointer;
  background: #fafafa;
  width: 50px;
  height: 180px;
  margin: 0 2px;
  border-radius: 0 0 3px 3px;
  border: none;
  padding: 10px;
  outline: none;
  box-sizing: border-box;
  color: #444;
}

.the-piano__natural-key--playing {
  border-bottom: 10px solid #ed5276;
  color: #ed5276;
}

.the-piano__natural-key:first-of-type {
  margin-left: 0;
}

.the-piano__natural-key:last-of-type {
  margin-right: 0;
}

.the-piano__text {
  pointer-events: none;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  font-size: 1rem;
}
/* .pitch-trainer-button {
  margin: theme.spacing.unit;
}

.pitch-trainer-leftIcon {
  marginRight: theme.spacing.unit;
}

rightIcon {
  marginLeft: theme.spacing.unit,
}

iconSmall {
  fontSize: 20; 
}
*/

.pitch-trainer-stat-table {
  width: 100%;
  max-width: 80vw;
}
.midi-trainer-body {
  width: 100%;
}
